import React from 'react';
import {trans} from '../locales';

export default function WhyChooseUs() {
    return (
      <section className="choose-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          {/* <span className="sub-title">Why Choose Us</span> */}
          <h2>{trans('home.services-title')}</h2>
        </div>
        <div className="row">
          <div className="col-sm-6 col-lg-3">
            <div className="choose-item">
              <div className="icon">
                <i className="flaticon-hand-washing" />
              </div>
              <h3>{trans('home.testimonial-bubble-1-header')}</h3>
              <p>{trans('home.testimonial-bubble-1-text')}</p>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="choose-item">
              <div className="icon">
                <i className="flaticon-sprout" />
              </div>
              <h3>{trans('home.testimonial-bubble-2-header')}</h3>
              <p>{trans('home.testimonial-bubble-2-text')}</p>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="choose-item">
              <div className="icon">
                <i className="flaticon-cost" />
              </div>
              <h3>{trans('home.testimonial-bubble-3-header')}</h3>
              <p>{trans('home.testimonial-bubble-3-text')}</p>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="choose-item">
              <div className="icon">
                <i className="flaticon-like" />
              </div>
              <h3>{trans('home.testimonial-bubble-4-header')}</h3>
              <p>{trans('home.testimonial-bubble-4-text')}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    )
}