import React from 'react';
import {trans} from '../locales';

export default function ContactForm() {
    return (
      <section className="testimonial-area contact-area ptb-100">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">{trans('home.contact-subtitle')}</span>
        </div>
        <div className="col-lg-6 offset-3">
          <div className="contact-item">
            <form id="contactForm">
              <div className="row">
                <div className="col-sm-12 col-lg-12">
                  <div className="form-group">
                    <select className="form-control">
                      <option>Select a request type</option>
                      <option>Prequalification</option>
                      <option>Inquiry</option>
                    </select>
                    <div className="help-block with-errors" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-lg-6">
                  <div className="form-group">
                    <input type="text" name="name" id="name" className="form-control" required data-error="Please enter your name" placeholder="Name" />
                    <div className="help-block with-errors" />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-6">
                  <div className="form-group">
                    <input type="email" name="email" id="email" className="form-control" required data-error="Please enter your email" placeholder="Email" />
                    <div className="help-block with-errors" />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-6">
                  <div className="form-group">
                    <input type="text" name="phone_number" id="phone_number" required data-error="Please enter your number" className="form-control" placeholder="Phone" />
                    <div className="help-block with-errors" />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-6">
                  <div className="form-group">
                    <input type="text" name="msg_subject" id="msg_subject" className="form-control" required data-error="Please enter your subject" placeholder="Subject" />
                    <div className="help-block with-errors" />
                  </div>
                </div>
                <div className="col-md-12 col-lg-12">
                  <div className="form-group">
                    <textarea name="message" className="form-control" id="message" cols={30} rows={8} required data-error="Write your message" placeholder="Message" defaultValue={""} />
                    <div className="help-block with-errors" />
                  </div>
                </div>
                <div className="col-md-12 col-lg-12">
                  <button type="submit" className="cmn-btn btn">
                    Send Message
                  </button>
                  <div id="msgSubmit" className="h3 text-center hidden" />
                  <div className="clearfix" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    )
}