// @ts-ignore
import Lang from 'lang.js'

const lang = new Lang({
    messages: {
        "en": require('./json/en.json'),
        "fr": require('./json/fr.json')
    },
    locale: 'en',
    fallback: 'en'
  });

export const trans = (key: string, replacements?: {}, locale?: string) => lang.trans(key, replacements, locale);
export default lang;