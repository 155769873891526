import React from 'react';
import {trans} from '../locales';

export default function Copyright() {
    return (
      <div className="copyright-area">
        <div className="container">
          <div className="copyright-item">
            <p>{trans('footer.copyright')} {trans('footer.designed-by')} <a href={trans('footer.designer-link')} target="_blank">{trans('footer.designer-name')}</a></p>
          </div>
        </div>
      </div>
    )
}