import React from 'react'
import Banner from './components/Banner';
import ContactForm from './components/ContactForm';
import Copyright from './components/Copyright';
import Feature from './components/Feature';
import Footer from './components/Footer';
import Header from './components/Header';
import HelpCard from './components/HelpCard';
import Navbar from './components/Navbar';
import Testimonial from './components/Testimonial';
import Values from './components/Values';
import WhyChooseUs from './components/WhyChooseUs';

function Home() {
  return (
    <div>
      <Header/>
      <Navbar/>
      <Banner/>
      <Feature/>
      <Testimonial/>
      <WhyChooseUs/>
      <Values/>
      <HelpCard/>
      <ContactForm/>
      <Footer/>
      <Copyright/>
    </div>
  );
}

export default Home