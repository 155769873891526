import React from 'react';
import {trans} from '../locales';

export default function Banner() {
    return (
      <div className="banner-area">
      <div className="banner-overlay"/>
      <div className="container-fluid" style={{paddingTop: '145px'}}>
        <div className="row m-0 align-items-center">
          <div className="col-lg-6">
            <div className="banner-content">
              <h1>{trans('home.hero-title')}</h1>
              <span>{trans('home.hero-subtitle')}</span>
              <p>{trans('home.hero-text')}</p>
              <a className="cmn-btn" href="about.html">{trans('home.button-contact-now')}</a>
              <a className="banner-btn" href="service.html">{trans('home.button-get-quote')}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}