import React from 'react';
import logo from './logo.svg';
import Home from './Home';

function App() {
  return (
   <Home/>
  );
}

export default App;
