import React from 'react';
import {trans} from '../locales';

export default function Testimonial() {
    return (
      <section className="project-area ptb-100">
      <div className="container">
        <div className="section-title">
          <h2>{trans('home.testimonial-quote')}</h2>
          <span className="sub-title">{trans('home.testimonial-name')}</span>
        </div>
      </div>
    </section>
    )
}