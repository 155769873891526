import React from 'react';
import {trans} from '../locales';

export default function Header() {
    return (
        <div className="header-top-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="header-item">
                <ul>
                  <li>
                    <i className="flaticon-email" />
                    <a href="mailto:abryant@multi-prets.ca">{trans('header.email')}</a>
                  </li>
                  <li>
                    <i className="flaticon-call" />
                    <a href={`tel:${trans('contact.phone')}`}>{trans('contact.phone')}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="header-item header-right">
                <ul>
                  <li>
                    <i className="flaticon-clock" />
                    <span>{trans('header.opening-hours')}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}