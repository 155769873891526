import React from 'react';
import {trans} from '../locales';

export default function Values() {
    return (
      <section className="virtual-area">
      <div className="virtual-shape">
        <img src="assets/img/home-one/virtual-shape1.png" alt="Shape" />
      </div>
      <div className="container-fluid">
        <div className="row m-0">
          <div className="col-lg-6">
            <div className="virtual-content">
              <div className="section-title">
                <h2>{trans('home.values-title')}</h2>
              </div>
              <p>{trans('home.values-text-1')}</p>
              <span>{trans('home.values-text-2')}</span>
              <ul>
                <li>
                  <i className="flaticon-support" />
                  <h4>{trans('home.values-1-header')}</h4>
                  <p>{trans('home.values-1-text')}</p>
                </li>
                <li>
                  <i className="flaticon-team" />
                  <h4>{trans('home.values-2-header')}</h4>
                  <p>{trans('home.values-2-text')}</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="virtual-img">
              <img className="virtual-img" src="assets/img/home2.jpg" alt="Virtual" />
              <div className="row m-0">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    )
}