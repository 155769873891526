import React from 'react';
import {trans} from '../locales';

export default function Footer() {
    return (
      <footer className="pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-lg-3">
            <div className="footer-item">
              <div className="footer-logo">
                <a className="logo" href="index.html">
                  <img src="assets/img/logo-white.png" alt="Logo" />
                </a>
                <p>{trans('footer.excerpt')}</p>
                <ul>
                  <li>
                    <a href="#" target="_blank">
                      <i className="bx bxl-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="bx bxl-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="bx bxl-pinterest-alt" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="bx bxl-linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="footer-item">
              <div className="footer-service footer-head">
                <h3>{trans('footer.our-services')}</h3>
                <ul>
                  <li>
                    <a href="service-details.html" target="_blank">
                      <i className="bx bx-chevrons-right" />
                      {trans('home.feature-purchase')}
                    </a>
                  </li>
                  <li>
                    <a href="service-details.html" target="_blank">
                      <i className="bx bx-chevrons-right" />
                      {trans('home.feature-financing')}
                    </a>
                  </li>
                  <li>
                    <a href="service-details.html" target="_blank">
                      <i className="bx bx-chevrons-right" />
                      {trans('home.feature-renewing')}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="footer-item">
              <div className="footer-service footer-head">
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="footer-item">
              <div className="footer-info footer-head">
                <h3>{trans('footer.information')}</h3>
                <ul>
                  <li>
                    <i className="bx bxs-phone-call" />
                    <h4>Phone</h4>
                    <a href="tel:882569756">{trans('contact.phone')}</a>
                  </li>
                  <li>
                    <i className="bx bxs-message-alt-detail" />
                    <h4>Email</h4>
                    <a href="mailto:info@lizel.com">{trans('contact.email')}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    )
}