import React from 'react';
import {trans} from '../locales';

export default function Feature() {
    return (
      <section className="feature-area pt-100 pb-70">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="feature-img">
              <div className="feature-main">
                <img src="assets/img/Picture.png" alt="Feature" />
              </div>
              <img src="assets/img/home-one/feature-shape1.png" alt="Shape" />
              <img src="assets/img/home-one/feature-shape1.png" alt="Shape" />
              <div className="feature-inner">
                <h3>{trans("home.feature-years-amount")}</h3>
                <span>{trans("home.feature-years")}</span>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="feature-content">
              <div className="section-title">
                <h2>{trans('home.feature-title')}</h2>
                <span className="sub-title">{trans('home.feature-subtitle')}</span>
              </div>
              <p>{trans('home.feature-text-1')}</p>
              <p>{trans('home.feature-text-2')}</p>
              <ul>
                <li>
                  <i className="flaticon-checked" />
                  {trans('home.feature-purchase')}
                </li>
                <li>
                  <i className="flaticon-checked" />
                  {trans('home.feature-financing')}
                </li>
                <li>
                  <i className="flaticon-checked" />
                  {trans('home.feature-renewing')}
                </li>
                <li>
                  <i className="flaticon-checked" />
                  {trans('home.feature-transfers')}
                </li>
              </ul>
              <a className="cmn-btn" href="about.html">{trans('home.button-contact-now')}</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    )
}