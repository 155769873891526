import React from 'react';
import {trans} from '../locales';

export default function Navbar() {
    return (
      <div className="navbar-area sticky-top">
      {/* Menu For Mobile Device */}
      <div className="mobile-nav">
        <a href="index.html" className="logo">
          <img src="assets/img/logo-white.png" alt="Logo" />
        </a>
      </div>
      {/* Menu For Desktop Device */}
      <div className="main-nav">
        <div className="container">
          <nav className="navbar navbar-expand-md navbar-light">
            <a className="navbar-brand" href="index.html">
              <img src="assets/img/logo-white.png" alt="Logo" />
            </a>
            <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
              <ul className="navbar-nav">
              </ul>
              <div className="side-nav"> 
                <a className="nav-btn" href="#">{trans('home.button-get-quote')}</a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
    )
}