import React from 'react';
import {trans} from '../locales';

export default function HelpCard() {
    return (
      <div className="help-area">
      <div className="container">
        <div className="help-content">
          <div className="section-title">
            <span className="sub-title">{trans('home.help-header')}</span>
            <h2>{trans('home.help-text')}</h2>
          </div>
          <a className="cmn-btn" href="#">{trans('home.button-get-quote')}</a>
        </div>
      </div>
    </div>
    )
}